//INsert languages here

export const LANGUAGE = {
  en: {
    GREETINGS: "Sincerely,",
    FOLLOW_ME: "Follow Me!",
    FOLLOW_INHEADEN: "Follow Inheaden!",
    INHEADEN_COMPANY: "Inheaden GmbH",
    URL_REDIRECT: "https://inheaden.io",
    URL_DISPLAY: "www.inheaden.io",
    INFO_EMAIL: "info@inheaden.io",
    PHONE_NUMBER: "+49 6151 394698-0",
    PHONE_NUMBER_URL: "tel:+49 6151 394698-0",
    COMPANY_HEADQUARTER: {
      NAME: "Company Headquarters",
      TEXT: "Hilpertstraße 31, 64295 Darmstadt, Germany",
    },
    REGISTER_COURT: {
      NAME: "District court",
      TEXT: "District Court Darmstadt",
    },
    COMMERCIAL_REGISTRATION_NUMBER: {
      NAME: "Commercial Register Number",
      TEXT: "HRB 100224",
    },
    EXECUTIVE_DIRECTOR: {
      NAME: "Executive Director",
      TEXT: "Dennis Kohl (CEO)",
    },
    AUTHORIZED_OFFICERS: {
      NAME: "Authorized Officers",
      TEXT: "Lars Gröber (CTO), Christian Hein (CIO)",
    },
    DISCLAIMER: {
      SECTION_1: `This e-mail may contain trade secrets or privileged, undisclosed or
      otherwise confidential information. If you have received this e-mail
      in error, you are hereby notified that any review, copying or
      distribution of it is strictly prohibited. Please inform us
      immediately and destroy the original transmittal. Thank you for your
      cooperation.`,
      SECTION_2: `Please do not print this email unless it is absolutely necessary.
      Every time you don't print an email, you are helping the environment.`,
      SECTION_3: `All information according to § 35a GmbHG according to German law.`,
    },
  },
  de: {
    GREETINGS: "Mit freundlichen Grüßen",
    FOLLOW_ME: "Folgen Sie Mir!",
    FOLLOW_INHEADEN: "Folgen Sie Inheaden!",
    INHEADEN_COMPANY: "Inheaden GmbH",
    URL_REDIRECT: "https://inheaden.io",
    URL_DISPLAY: "www.inheaden.io",
    INFO_EMAIL: "info@inheaden.io",
    PHONE_NUMBER: "+49 6151 394698-0",
    PHONE_NUMBER_URL: "tel:+49 6151 394698-0",
    COMPANY_HEADQUARTER: {
      NAME: "Sitz der Gesellschaft",
      TEXT: "Hilpertstraße 31, 64295 Darmstadt, Deutschland",
    },
    REGISTER_COURT: {
      NAME: "Registergericht",
      TEXT: "Amtsgericht Darmstadt",
    },
    COMMERCIAL_REGISTRATION_NUMBER: {
      NAME: "Handelsregisternummer",
      TEXT: "HRB 100224",
    },
    EXECUTIVE_DIRECTOR: {
      NAME: "Geschäftsführer",
      TEXT: "Dennis Kohl (CEO)",
    },
    AUTHORIZED_OFFICERS: {
      NAME: "Prokuristen",
      TEXT: "Lars Gröber (CTO), Christian Hein (CIO)",
    },
    DISCLAIMER: {
      SECTION_1: `Diese E-Mail enthält vertrauliche und/oder rechtlich geschützte Informationen.
      Wenn Sie nicht der richtige Adressat sind, oder diese E-Mail irrtümlich erhalten haben, informieren Sie bitte den Absender und löschen Sie diese Mail. Das unerlaubte Kopieren sowie die unbefugte Weitergabe dieser E-Mail und der darin enthaltenen Informationen sind nicht gestattet.`,
      SECTION_2: `Bitte drucken Sie diese E-Mail nicht aus, es sei denn, dies ist unbedingt erforderlich.
      Jedes Mal, wenn Sie keine E-Mail drucken, helfen Sie der Umwelt.`,
      SECTION_3: `Alle Angaben nach § 35a GmbHG.`,
    },
  },

  en_uae: {
    GREETINGS: "Sincerely,",
    FOLLOW_ME: "Follow Me!",
    FOLLOW_INHEADEN: "Follow Inheaden!",
    INHEADEN_COMPANY: "Inheaden L.L.C-FZ",
    URL_REDIRECT: "http://inheaden.ae",
    URL_DISPLAY: "www.inheaden.ae",
    INFO_EMAIL: "info@inheaden.ae",
    PHONE_NUMBER: "+49 6151 394698-0",
    PHONE_NUMBER_URL: "tel:+49 6151 394698-0",
    COMPANY_HEADQUARTER: {
      NAME: "Company Address",
      TEXT: "Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E",
    },
    REGISTER_COURT: {
      NAME: "District Rules and Regulations",
      TEXT: "Meydan Free Zone",
    },
    COMMERCIAL_REGISTRATION_NUMBER: {
      NAME: "License Number",
      TEXT: " 2202829.01",
    },
    EXECUTIVE_DIRECTOR: {
      NAME: "Executive Director",
      TEXT: "Dennis Kohl (CEO)",
    },
    AUTHORIZED_OFFICERS: {
      NAME: "Managing Director",
      TEXT: "Sunil Kumar (Director of Operations Asia)",
    },
    DISCLAIMER: {
      SECTION_1: `This e-mail may contain trade secrets or privileged, undisclosed or
        otherwise confidential information. If you have received this e-mail
        in error, you are hereby notified that any review, copying or
        distribution of it is strictly prohibited. Please inform us
        immediately and destroy the original transmittal. Thank you for your
        cooperation.`,
      SECTION_2: `Please do not print this email unless it is absolutely necessary.
        Every time you don't print an email, you are helping the environment.`,
    },
  },

  en_india: {
    GREETINGS: "Sincerely,",
    FOLLOW_ME: "Follow Me!",
    FOLLOW_INHEADEN: "Follow Inheaden!",
    INHEADEN_COMPANY: "Inheaden India Pvt. Ltd.",
    URL_REDIRECT: "https://inheaden.io",
    URL_DISPLAY: "www.inheaden.io",
    INFO_EMAIL: "info@inheaden.io",
    PHONE_NUMBER: "+49 6151 394698-0",
    PHONE_NUMBER_URL: "tel:+49 6151 394698-0",
    COMPANY_HEADQUARTER: {
      NAME: "Company Address",
      TEXT: "404, Chitrakut Dham-2, C.V. Raman Nagar, Bangalore - 560 093",
    },
    REGISTER_COURT: {
      NAME: "District Rules and Regulations",
      TEXT: "-",
    },
    COMMERCIAL_REGISTRATION_NUMBER: {
      NAME: "License Number",
      TEXT: "-",
    },
    EXECUTIVE_DIRECTOR: {
      NAME: "Executive Director",
      TEXT: "Dennis Kohl (CEO)",
    },
    AUTHORIZED_OFFICERS: {
      NAME: "Managing Director",
      TEXT: "Sunil Kumar (Director of Operations Asia)",
    },
    DISCLAIMER: {
      SECTION_1: `This e-mail may contain trade secrets or privileged, undisclosed or
        otherwise confidential information. If you have received this e-mail
        in error, you are hereby notified that any review, copying or
        distribution of it is strictly prohibited. Please inform us
        immediately and destroy the original transmittal. Thank you for your
        cooperation.`,
      SECTION_2: `Please do not print this email unless it is absolutely necessary.
        Every time you don't print an email, you are helping the environment.`,
    },
  },
};
