export const SIGNATURE = `
<style>
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

  @font-face {
    font-family: Roboto;
    src: local("Roboto")
      url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
  }

  /* Stop Outlook resizing small text. */
  * {
    -ms-text-size-adjust: 100%;
  }

  /* Stop Outlook from adding extra spacing to tables. */
  table,
  td {
    mso-table-lspace: 0pt !important;
    mso-table-rspace: 0pt !important;
  }

  /* Use a better rendering method when resizing images in Outlook IE. */
  img {
    -ms-interpolation-mode: bicubic;
  }

  /* Prevent Windows 10 Mail from underlining links. Styles for underlined links should be inline. */
  a {
    text-decoration: none;
  }
</style>

<div
  class="signature"
  style="
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    max-width: 1000px;
  "
>
  <p>{{GREETINGS}}</p>

  <table
    style="
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;
      max-width: 1000px;
    "
    role="presentation"
    cellspacing="0"
    cellpadding="0"
    border="0"
  >
    <!-- START OF PERSONAL DETAILS -->
    <tr>
      <td width="250" class="padding-bottom: 4px" style="white-space: nowrap;">
        <p
          style="
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 0 4px 0;
          "
        >
          {{name}}
        </p>
        {{position}}
      </td>
      <td width="99%">&nbsp;</td>
      <td style="text-align: left; white-space: nowrap;" width="190">
        <table
          style="
            border-spacing: 0;
            border-collapse: collapse;
            width: 190px;
            float: right;
          "
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td>
              <a href="https://inheaden.io">
                <img
                  src="https://cdn.inheaden.cloud/signature/RR-logo.png"
                  alt="Inheaden"
                  width="150"
                  style="width: 150px;"
                />
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="border-bottom: solid 2px #0093e5;" colspan="3"></td>
    </tr>
    <tr>
      <td
        colspan="2"
        style="
          text-decoration: none;
          color: #000;
          vertical-align: top;
          padding-top: 6px
        "
      >
        <table
          style="border-spacing: 0; border-collapse: collapse;"
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          {{#landline}}
          <tr>
            <td style="text-align: left; padding: 2px 0 2px 0;">
              <table
                style="border-spacing: 0; border-collapse: collapse;"
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tr>
                  <td style="padding-right: 12px;">
                    <img
                      src="https://cdn.inheaden.cloud/signature/phone.png"
                      alt=""
                      width="14"
                      height="14"
                      style="width: 14px;"
                    />
                  </td>
                  <td>
                    <a
                      style="text-decoration: none; color: #000; line-height: 20px;"
                      href="tel:{{landline}}"
                      >{{landline}}</a
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          {{/landline}} {{#mobile}}
          <tr>
            <td style="text-align: left; padding: 2px 0 2px 0; line-height: 20px;">
              <table
                style="border-spacing: 0; border-collapse: collapse;"
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tr>
                  <td style="padding-right: 12px;">
                    <img
                      src="https://cdn.inheaden.cloud/signature/mobile.png"
                      alt=""
                      width="14"
                      height="14"
                      style="width: 14px;"
                    />
                  </td>
                  <td>
                    <a
                      style="text-decoration: none; color: #000;"
                      href="tel:{{mobile}}"
                      >{{mobile}}</a
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          {{/mobile}}
          <tr>
            <td style="text-align: left; padding: 2px 0 2px 0; line-height: 20px;">
              <table
                style="border-spacing: 0; border-collapse: collapse;"
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tr>
                  <td style="padding-right: 12px;">
                    <img
                      src="https://cdn.inheaden.cloud/signature/email.png"
                      alt=""
                      width="14"
                      height="14"
                      style="width: 14px;"
                    />
                  </td>
                  <td>
                    <a
                      style="text-decoration: none; color: #000;"
                      href="mailto:{{email}}"
                      >{{email}}</a
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
      <td style="vertical-align: top; padding-top: 6px">
        {{#followMe}}
        <table
          style="
            border-spacing: 0;
            border-collapse: collapse;
            width: 180px;
            margin-left: 10px;
            float: right;
            vertical-align: top;
          "
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td
              style="
                text-align: left;
                vertical-align: top;
                line-height: 20px;
                padding: 2px 0 2px 0;
              "
            >
              <strong>{{FOLLOW_ME}}</strong>
            </td>
          </tr>
          <tr>
            <td style="text-align: left; padding: 2px 0 2px 0;">
              <table
                style="
                  border-spacing: 0;
                  border-collapse: collapse;
                vertical-align: top;
                line-height: 22px;
                width: 180px;
                "
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tr>
                  <td
                    style="
                      text-align: left;
                      line-height: 22px;
                      color: #0093e5;
                      vertical-align: top;
                    "
                  >
                    {{#followMeLinks}}
                    <a
                      href="{{url}}"
                      style="color: #0093e5; text-decoration: none;"
                      >{{name}} </a
                    >{{#pipe}} | {{/pipe}}{{/followMeLinks}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        {{/followMe}}
      </td>
    </tr>

    <!-- END OF PERSONAL DETAILS -->
    <!-- START OF COMPANY CONTACT -->
    <tr>
      <td
        style="border-bottom: solid 1px #a6a6a6; padding-top: 8px;"
        colspan="3"
      ></td>
    </tr>
    <tr>
      <td colspan="2" style="padding: 12px 0 4px 0;">
        <strong>{{INHEADEN_COMPANY}}</strong>
      </td>
      <td style="text-align: right; padding: 12px 0 4px 0;">
        <table
          style="
            border-spacing: 0;
            border-collapse: collapse;
            width: 180px;
            margin-left: 10px;
            float: right;
          "
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td
              style="
                text-align: left;
                line-height: 20px;
                vertical-align: bottom;
              "
            >
              <strong>{{FOLLOW_INHEADEN}}</strong>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding: 4px 0;">
        <table
          style="border-spacing: 0; border-collapse: collapse;"
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td style="padding-right: 12px;">
              <img
                src="https://cdn.inheaden.cloud/signature/website.png"
                alt=""
                width="14"
                height="14"
                style="width: 14px;"
              />
            </td>
            <td>
              <a
                href="{{URL_REDIRECT}}"
                style="text-decoration: none; color: #000;"
                >{{URL_DISPLAY}}</a
              >
            </td>
          </tr>
        </table>
      </td>
      <td rowspan="3" style="text-align: right; vertical-align: top;">
        <table
          style="
            border-spacing: 0;
            border-collapse: collapse;
            width: 180px;
            margin-left: 10px;
            float: right;
          "
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td
              style="
                text-align: left;
                line-height: 22px;
                color: #0093e5;
                vertical-align: top;
              "
            >
              <a
                href="https://linkedin.com/company/inheaden"
                style="color: #1795e2; text-decoration: none;"
              >
                LinkedIn
              </a>
              |
              <a
                href="https://twitter.com/inheaden"
                style="color: #1795e2; text-decoration: none;"
              >
                Twitter
              </a>
              |
              <a
                href="https://xing.com/companies/inheaden"
                style="color: #1795e2; text-decoration: none;"
              >
                Xing
              </a>
              | <br />
              <a
                href="https://instagram.com/inheaden"
                style="color: #1795e2; text-decoration: none;"
              >
                Instagram
              </a>
              |
              <a
                href="https://facebook.com/inheaden"
                style="color: #1795e2; text-decoration: none;"
              >
                Facebook
              </a>
              | <br />
              <a
                href="https://medium.com/inheaden"
                style="color: #1795e2; text-decoration: none;"
              >
                Medium
              </a>
              |
              <a
                href="https://youtube.com/channel/UCrXoyhtslmsBNAsoXsYa46w"
                style="color: #1795e2; text-decoration: none;"
              >
                Youtube
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding: 4px 0;">
        <table
          style="border-spacing: 0; border-collapse: collapse;"
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td style="padding-right: 12px;">
              <img
                src="https://cdn.inheaden.cloud/signature/phone.png"
                alt=""
                width="14"
                height="14"
                style="width: 14px;"
              />
            </td>
            <td>
              <a
                href={{PHONE_NUMBER_URL}}
                style="text-decoration: none; color: #000;"
                >{{PHONE_NUMBER}}</a
              >
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding: 4px 0;">
        <table
          style="border-spacing: 0; border-collapse: collapse;"
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td style="padding-right: 12px;">
              <img
                src="https://cdn.inheaden.cloud/signature/email.png"
                alt=""
                width="14"
                height="14"
                style="width: 14px;"
              />
            </td>
            <td>
              <a
                href="mailto:info@inheaden.io"
                style="text-decoration: none; color: #000;"
                >{{INFO_EMAIL}}</a
              >
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- END OF COMPANY CONTACT -->
    <!-- START OF COMPANY DETAILS -->

    <tr>
      <td
        style="border-bottom: solid 1px #a6a6a6; padding-top: 8px;"
        colspan="3"
      ></td>
    </tr>
    <tr>
      <td colspan="3">
        <table
          style="
            border-spacing: 0;
            border-collapse: collapse;
            max-width: 600px;
            color: #7a7a7a;
          "
          role="presentation"
          cellspacing="0"
          cellpadding="0"
          border="0"
        >
          <tr>
            <td
              style="padding: 4px 0; width: 250px; padding: 12px 0 4px 0;"
              width="250"
            >
              {{COMPANY_HEADQUARTER.NAME}}
            </td>
            <td style="padding: 12px 0 4px 0;">
              {{COMPANY_HEADQUARTER.TEXT}}
            </td>
          </tr>
          <tr>
            <td style="padding: 4px 0;">
              {{REGISTER_COURT.NAME}}
            </td>
            <td>
              {{REGISTER_COURT.TEXT}}
            </td>
          </tr>
          <tr>
            <td style="padding: 4px 0;">
              {{COMMERCIAL_REGISTRATION_NUMBER.NAME}}
            </td>
            <td>
              {{COMMERCIAL_REGISTRATION_NUMBER.TEXT}}
            </td>
          </tr>
          <tr>
            <td style="padding: 4px 0;">
              {{EXECUTIVE_DIRECTOR.NAME}}
            </td>
            <td>
              {{EXECUTIVE_DIRECTOR.TEXT}}
            </td>
          </tr>
          <tr>
            <td style="padding: 4px 0;">
              {{AUTHORIZED_OFFICERS.NAME}}
            </td>
            <td>
              {{AUTHORIZED_OFFICERS.TEXT}}
            </td>
          </tr>
        </table>
      </td>
    </tr>

    <!-- END OF COMPANY DETAILS -->

    <tr>
      <td
        style="border-bottom: solid 1px #a6a6a6; padding-top: 8px;"
        colspan="3"
      ></td>
    </tr>
    <tr>
      <td
        colspan="3"
        style="color: #7a7a7a; padding: 12px 0 4px 0; line-height: 16px;"
      >
        <p style="margin-top: 0;">
          {{DISCLAIMER.SECTION_1}}
        </p>
        <p>
          {{DISCLAIMER.SECTION_2}}
        </p>
        <p>
          {{DISCLAIMER.SECTION_3}}
        </p>
      </td>
    </tr>
  </table>
</div>

              `;
